<template>
  <v-row
    class="px-10"
    dense
  >
    <v-col
      rows="12"
      md="6"
    >
      <v-text-field
        v-model="agency.company_name"
        :rules="[() => !!agency.company_name || 'Este campo é obrigatório']"
        label="Razão Social"
        dense
        rounded
        outlined
      />
      <v-text-field
        v-model="agency.name"
        :rules="[() => !!agency.name || 'Este campo é obrigatório']"
        label="Nome*"
        placeholder="Digite o nome"
        outlined
        rounded
        dense
      />
      <!-- <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="birthday"
            label="Data de nascimento"
            append-icon="fas fa-calendar-alt"
            v-bind="attrs"
            v-on="on"
            readonly
            outlined
            rounded
            dense
          />
        </template>
        <v-date-picker
          v-model="agency.birthday"
          @change="saveDate"
          :active-picker.sync="activePicker"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          "
          min="1950-01-01"
        />
      </v-menu> -->
      <v-autocomplete
        v-model="agency.seller_id"
        :rules="[() => !!agency.seller_id || 'Este campo é obrigatório']"
        label="Vendedor*"
        :items="sellers"
        item-text="name"
        item-value="id"
        dense
        rounded
        outlined
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="agency.trading_name"
        label="Nome Fantasia"
        dense
        rounded
        outlined
      />

      <v-text-field
        v-model="agency.email"
        :rules="[
          () => !!agency.email || 'Este campo é obrigatório',
          rules.email,
        ]"
        label="Email*"
        placeholder="exemplo@gmail.com"
        outlined
        rounded
        dense
      />
      <v-text-field
        v-model="agency.contact"
        label="Telefone"
        v-mask="['(##) ####-####', '(##) #####-####']"
        placeholder="(xx)x.xxxx-xxxx"
        type="tel"
        outlined
        rounded
        dense
      />
    </v-col>
    <!-- <v-row class="" dense>
      <v-col cols="12" md="6">
        <v-autocomplete
        v-model="agency.seller_id"
        label="Vendedor*"
        :items="sellers"
        item-text="name"
        item-value="id"
        dense
        rounded
        outlined
      />
      </v-col>

    </v-row> -->
  </v-row>
</template>

<script>
export default {
  props: {
    agency: Object,
  },
  data: () => ({
    menu: false,
    activePicker: null,
    isLoading: false,
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Insira um email válido'
      },
    },
    sellers: null,
  }),
  computed: {
    birthday: function () {
      if (this.agency.birthday != null) {
        let BR_dateFormat = this.agency.birthday.split('-')
        BR_dateFormat = BR_dateFormat.reverse()
        BR_dateFormat = BR_dateFormat.join('/')
        return BR_dateFormat
      } else {
        return ''
      }
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    getSellers() {
      this.isLoading = true

      this.$api
        .get('people/type/sellers')
        .then((res) => {
          this.sellers = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  created() {
    this.getSellers()
  },
}
</script>
